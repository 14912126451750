import { useQueryParams } from 'hooks/useParams';
import React, {
  FC,
  ReactNode,
  useState,
  ReactElement,
  Children,
  useEffect,
  useCallback,
  useReducer,
  Reducer,
  cloneElement,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { EventEmitter } from 'utils/eventEmitter';
import { toQueryString } from 'utils/url';
import { TeamParams } from 'views/team/TeamPage';
import './Tabs.css';

type TTab = {
  id?: number;
  name: string;
  heading: ReactNode | ((props: { active: boolean }) => ReactNode);
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  order?: number;
  deletable?: boolean;
  editable?: boolean;
  selected?: boolean;
  count?: number;
  setCount?: (count: number) => void;
};

export type TTabPanel = {
  count: number;
  setCount: (count: number) => void;
  visible: boolean;
};

interface TabsProps {
  selected?: string;
  id?: string;
  onSelect?: (name: string) => void;
  className?: string;
  fixedHeight?: boolean;
  showCount?: boolean;
  children?: ReactNode;
}

export const Tabs: FC<TabsProps> = ({
  children,
  className,
  onSelect,
  selected: initial,
  id,
  fixedHeight = true,
  showCount,
}) => {
  const tabs = Children.map(
    children || [],
    (child) => (child as ReactElement<TTab>)?.props,
  );
  const [selected, setSelected] = useState(
    initial || (tabs[0] && tabs[0].name) || undefined,
  );

  const [tabCounts, setTabCounts] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    initial && setSelected(initial);
  }, [initial]);

  EventEmitter.subscribe(`tab-select-${id}`, (tab: string) => {
    setSelected(tab);
  });

  const setCountForTab = useCallback(
    (tabName: string, count: number) => {
      setTabCounts((prevCounts) => {
        if (prevCounts[tabName] !== count) {
          return { ...prevCounts, [tabName]: count };
        }
        return prevCounts;
      });
    },
    [tabCounts],
  );

  return (
    <>
      <TabList
        id={id}
        tabs={tabs.map((tab) => ({
          ...tab,
          count: showCount ? tabCounts[tab.name] || 0 : null,
        }))}
        selected={selected}
        onClick={(tab) => {
          setSelected(tab.name);
          onSelect && onSelect(tab.name);
        }}
        className={className}
        fixedHeight={fixedHeight}
      />

      {showCount
        ? tabs.map((tab, i) => (
            <TabPanel
              key={i}
              tab={{
                ...tab,
                selected: selected === tab.name,
                setCount: (count: number) => setCountForTab(tab.name, count),
              }}
            />
          ))
        : tabs.map((tab, i) => {
            return selected === tab.name && <TabPanel key={i} tab={tab} />;
          })}
    </>
  );
};

export const RouteTabs: FC<{
  onSelect?: (name: string) => void;
  className?: string;
  match: string;
  fixedHeight?: boolean;
}> = ({ children, onSelect, match, className, fixedHeight = true }) => {
  const tabs = Children.map(
    children || [],
    (child) => (child as ReactElement<TTab>)?.props,
  );

  const [params] = useQueryParams<TeamParams>({
    phrase: '',
    licence: '',
    page: 1,
    per_page: 30,
    groups_in: [],
    state: '',
  });

  const history = useHistory();
  const route = useRouteMatch<{ tab: string }>({ path: match });

  const url = (tab: TTab) => match.replace(':tab', tab.name);

  return (
    <>
      <TabList
        tabs={tabs}
        selected={route?.params.tab}
        onClick={(tab) => {
          if (onSelect) {
            onSelect(tab.name);
          } else {
            history.replace({
              pathname: url(tab),
              search: toQueryString(params),
            });
          }
        }}
        className={className}
        fixedHeight={fixedHeight}
      />

      <Switch>
        {tabs.map((tab, i) => (
          <Route path={url(tab)} key={i}>
            <TabPanel tab={tab} />
          </Route>
        ))}

        <Route>
          <Redirect to={`${url(tabs[0])}?${toQueryString(params)}`} />
        </Route>
      </Switch>
    </>
  );
};

export const RouteTabsV2: FC<{
  onSelect?: (name: string) => void;
  className?: string;
  fixedHeight?: boolean;
  rememberSearchQuery?: boolean;
  children?: ReactNode;
}> = ({
  children,
  onSelect,
  className,
  fixedHeight = true,
  rememberSearchQuery,
}) => {
  const tabs = Children.map(
    children || [],
    (child) => (child as ReactElement<TTab>)?.props,
  );

  const history = useHistory();

  const matches = useRouteMatch();

  const selectedTab =
    history.location.pathname.split('/')[
      history.location.pathname.split('/').length - 1
    ] || '';

  return (
    <>
      <TabList
        tabs={tabs}
        selected={selectedTab}
        onClick={(tab) => {
          if (onSelect) {
            onSelect(tab.name);
          } else {
            history.replace({
              pathname: tab.name,
              state: history.location.state,
              search: rememberSearchQuery && history.location.search,
            });
          }
        }}
        className={className}
        fixedHeight={fixedHeight}
      />

      <Switch>
        {tabs.map((tab, i) => (
          <Route path={`${matches.url}/${tab.name}`} key={i}>
            <TabPanel tab={tab} />
          </Route>
        ))}

        <Route>
          <Redirect to={`${matches.url}/${tabs[0]?.name}`} />
        </Route>
      </Switch>
    </>
  );
};

const TabList: FC<{
  tabs: TTab[];
  selected: string | undefined;
  onClick: (tab: TTab) => void;
  className?: string;
  fixedHeight: boolean;
  id?: string;
}> = ({ tabs, selected, onClick, className, fixedHeight = true, id }) => {
  return (
    <div
      style={{ overflow: 'auto' }}
      className="nice-horizontal-scroll-bar"
      id={`tab-list-${id}`}
    >
      <ul
        className={`flex border-b ${fixedHeight ? 'h-14' : ''} ${
          className || ''
        }`}
        role="tablist"
      >
        {tabs.map((tab, i) => {
          const selectedClass =
            selected === tab.name
              ? 'text-gray-800 border-blue-700'
              : 'text-gray-600 border-transparent-000';
          const disabledClass = tab.disabled
            ? null
            : 'focus:border-blue-700 hover:border-blue-700';
          const disabledCursor = tab.disabled
            ? 'cursor-not-allowed'
            : 'cursor-pointer';
          const hiddenClass = tab.hidden ? 'hidden' : '';

          return (
            <li className="h-full" id={tab.name} key={i}>
              <button
                role="tab"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => onClick(tab)}
                className={`${hiddenClass} relative h-full py-3 border-b-2 select-none ${disabledCursor} focus:outline-none ${disabledClass} ${selectedClass}  ${
                  tabs.length - 1 === i
                    ? ''
                    : tab.marginClass
                    ? tab.marginClass
                    : 'mr-10'
                }`}
                id={aria_tab(tab.name)}
                aria-controls={aria_panel(tab.name)}
                aria-selected={selected === tab.name}
                data-cy-tab={tab.name}
                type="button"
                disabled={tab.disabled}
              >
                {typeof tab.heading === 'function'
                  ? tab.heading({ active: selected === tab.name })
                  : tab.heading}
                {typeof tab?.count === 'number' && (
                  <span
                    className={`${
                      selected === tab.name ? ' bg-blue-100' : 'bg-gray-000'
                    } text-sm rounded-full px-2 py-1 ml-2`}
                  >
                    {tab.count}
                  </span>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const TabPanel: FC<{ tab: TTab; className?: string; children?: ReactNode }> = ({
  tab,
  className,
  children,
}) => {
  if (!tab.children) {
    return null;
  }

  const setCount = tab.setCount;

  const childrenWithProps = Children.map(tab.children, (child) => {
    if (React.isValidElement(child) && setCount) {
      return cloneElement(child as ReactElement, {
        setCount,
        visible: tab.selected,
      });
    }
    return child;
  });

  return (
    <div
      role="tabpanel"
      className={`${tab.className} ${className} ${
        tab.selected != null && (tab.selected ? '' : 'hidden')
      }`}
      id={aria_panel(tab.name)}
      aria-labelledby={aria_tab(tab.name)}
    >
      {childrenWithProps}
    </div>
  );
};

export const Tab: FC<TTab> = ({ children }) => {
  return <>{children}</>;
};

function aria_panel(tab: string): string {
  return `tabs-${tab}-panel`;
}

function aria_tab(tab: string): string {
  return `tabs-${tab}-tab`;
}

type TabState = {
  tabs: TTab[];
  selected: string | undefined;
  animation: 'left' | 'right';
};

enum ActionType {
  SetSelected = 'SET_SELECTED',
}

type TabAction = {
  type: ActionType;
  payload: {
    selected: string;
  };
};

const tabsReducer: Reducer<TabState, TabAction> = (state, action) => {
  switch (action.type) {
    case ActionType.SetSelected:
      const currentIndex = state.tabs.findIndex(
        (t) => t.name === state.selected,
      );
      const selectedIndex = state.tabs.findIndex(
        (t) => t.name === action.payload.selected,
      );

      return {
        ...state,
        selected: action.payload.selected,
        animation: currentIndex > selectedIndex ? 'left' : 'right',
      };
    default:
      return {
        ...state,
      };
  }
};

export const FormTabs: FC<{
  selected?: string;
  id?: string;
  onSelect?: (name: string) => void;
  onAnimateEnd?: () => void;
  className?: string;
}> = ({ children, className, onSelect, onAnimateEnd, selected: initial }) => {
  const tabs = Children.map(
    children || [],
    (child) => (child as ReactElement<TTab>).props,
  );

  const [state, dispatch] = useReducer(tabsReducer, {
    tabs: tabs,
    selected: initial || (tabs[0] && tabs[0].name) || undefined,
    // prev: undefined,
    animation: 'right',
  });

  useEffect(() => {
    if (initial) {
      dispatch({
        type: ActionType.SetSelected,
        payload: {
          selected: initial,
        },
      });
      onAnimateEnd && onAnimateEnd();
    }
  }, [initial]);

  const onTabClick = useCallback(
    (tab) => {
      dispatch({
        type: ActionType.SetSelected,
        payload: {
          selected: tab.name,
        },
      });
      onSelect && onSelect(tab.name);
    },
    [dispatch, onSelect],
  );

  return (
    <div className="w-full">
      <FormTabList
        tabs={tabs}
        selected={state.selected}
        onClick={onTabClick}
        className={className}
      />
      {tabs.map((tab, i) => {
        return (
          state.selected === tab.name && (
            <TabPanel
              key={i}
              tab={tab}
              className={
                state.animation === 'right'
                  ? 'animate-slide-in-right'
                  : 'animate-slide-in-left'
              }
            />
          )
        );
      })}
      <FormTabList
        tabs={tabs}
        selected={state.selected}
        onClick={onTabClick}
        className={className}
      />
    </div>
  );
};

const FormTabList: FC<{
  tabs: TTab[];
  selected: string | undefined;
  onClick: (tab: TTab) => void;
  className?: string;
}> = ({ tabs, selected, onClick, className }) => {
  const [index, setIndex] = useState<number>(
    tabs.findIndex((t) => selected === t.name),
  );

  useEffect(() => {
    setIndex(tabs.findIndex((t) => selected === t.name));
  }, [selected, tabs]);

  const onEnter = (id: number) => {
    setIndex(id);
  };

  const onLeave = useCallback(() => {
    setIndex(tabs.findIndex((t) => selected === t.name));
  }, [selected, tabs]);

  const cols = tabs.length;

  const columnClass = index + 1 !== cols ? `w-${index + 1}/${cols}` : 'w-full';

  return (
    <div className="w-full">
      <ul
        className={`grid grid-cols-${cols} w-full mb-3 h-14 ${className || ''}`}
        role="tablist"
      >
        {tabs.map((tab, i) => {
          const selectedClass =
            selected === tab.name ? 'text-blue-700' : 'text-gray-500';
          const disabledClass = tab.disabled ? null : 'hover:text-blue-700';
          const disabledCursor = tab.disabled
            ? 'cursor-not-allowed'
            : 'cursor-pointer';

          return (
            <li
              className="h-full text-center"
              key={i}
              onMouseEnter={() => onEnter(i)}
              onMouseLeave={() => onLeave()}
            >
              <button
                role="tab"
                onClick={() => onClick(tab)}
                className={`relative h-full ${disabledCursor} select-none focus:outline-none ${disabledClass} ${selectedClass}`}
                id={aria_tab(tab.name)}
                aria-controls={aria_panel(tab.name)}
                aria-selected={selected === tab.name}
                data-cy-tab={tab.name}
                type="button"
                disabled={tab.disabled}
              >
                {typeof tab.heading === 'function'
                  ? tab.heading({ active: selected === tab.name })
                  : tab.heading}
              </button>
            </li>
          );
        })}
      </ul>
      <div
        className={`${columnClass} bg-blue-700 h-px duration-700 easy-in-out`}
      />
    </div>
  );
};
